import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w02TA02Hund = () => (
  <Layout>
    <SEO title="w02TA02Hund" />

    <h1 id="ueberschrift">Tiere im Alltag</h1>
    <h2>Der Hund &emsp;  ©&nbsp;1989</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w02TA02Hund&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem">
        Daß mir der Hund das Liebste sei,<br />
        sagst du, o Mensch, sei Sünde?<br />
        Der Hund blieb mir im Sturme treu,<br />
        der Mensch nicht mal im Winde."<br />
        <div style={{fontSize: "0.75em"}}>Giovanni Battista Bernardone (Franziskus von Assisi (1182 - 1226))</div>
      </p>

    <p>---</p>

      <p>  
        So manch einer hat den "liebsten Hund der Welt", während andere Schwierigkeiten mit diesen vierbeinigen
        Zeitgenossen oder gar schlechte Erfahrungen gemacht haben. Die Ursachen für diese unterschiedlichen
        Erlebnisse können vielfältig sein und zugleich ähnlich bis identisch.
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Aus diesem Grund bietet dieser Kurs mehr als einen einfachen Frontal-Vortrag. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen.
        So können Sie Methoden entwickeln, welche Ihnen helfen können, diese Vierbeiner besser zu verstehen
        und&nbsp;/&nbsp;oder sogar die Bindung zu Ihrem (zukünftigen,) vierbeinigen Partner zu intensivieren.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Vortrag (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w02TA02Hund
